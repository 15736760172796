/*_______________________ FONTS_______________________*/
@font-face {
  font-family: "Roboto-medium";
  src: url('../../public/assets/fonts/roboto/Roboto-Medium.ttf');
}

@font-face {
  font-family: "Roboto-regular";
  src: url('../../public/assets/fonts/roboto/Roboto-Regular.ttf');
}
@font-face {
  font-family: "Roboto-medium";
  src: url('../../public/assets/fonts/roboto/Roboto-Medium.ttf');
}
@font-face {
  font-family: "Roboto-bold";
  src: url('../../public/assets/fonts/roboto/Roboto-Bold.ttf');
}
@font-face {
  font-family: "Roboto-black";
  src: url('../../public/assets/fonts/roboto/Roboto-Black.ttf');
}

$font-roboto-medium: 'Roboto-medium', sans-serif;
$font-roboto-regular: 'Roboto-regular', sans-serif;
$font-roboto-semibold: 'Roboto-medium', sans-serif;
$font-roboto-bold: 'Roboto-bold', sans-serif;
$font-roboto-black: 'Roboto-black', sans-serif;

$font-weight-medium: 400;
$font-weight-regular: 500;
$font-weight-semibold: 600;
$font-weight-bold: 900;


/*_______________________ COLORS _______________________*/
$dark: #000;
$dark-light: #4A4A4A;
$dark-extra-light: #9B9B9B;
$light: #fff;
$primary: #ED3F32;
$menu-light: #ECEDEF;

/*_______________________ DIMENSIONS _______________________*/
$header-height: 60px;

/*_______________________ MENU _______________________*/
$font-size-menu: 16px;

/*_______________________ CONTENT _______________________*/
$font-size-h1: 17px;
$line-height-h1: 20px;
$color-h1: $dark-light;

$font-size-h2: 14px;
$line-height-h2: 17px;
$color-h2: $primary;

$font-size-h3: 16px;
$line-height-h3: 19px;
$color-h3: $dark-light;

$font-size-p: 14px;
$line-height-p: 18px;
$color-p: $dark-light;


/*_______________________ OVER _______________________*/
:root {
  /** primary **/
  --ion-color-primary: #ED3F32;
  --ion-color-primary-rgb: 212, 180, 95;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #CE1F12;
  --ion-color-primary-tint: #F27269;

  /** secondary **/
  --ion-color-secondary: #C90119;
  --ion-color-secondary-rgb: 212, 180, 95;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #8E0111;
  --ion-color-secondary-tint: #FE0B28;

  /** tertiary **/
  --ion-color-tertiary: #9B9B9B;
  --ion-color-tertiary-rgb: 155, 155, 155;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #616161;
  --ion-color-tertiary-tint: #b8b8b8;

  /** success **/
  --ion-color-success: #00a440;
  --ion-color-success-rgb: 0, 164, 64;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #008e37;
  --ion-color-success-tint: #00ce50;

  /** warning **/
  --ion-color-warning: #f9c135;
  --ion-color-warning-rgb: 249, 193, 53;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #d5a52d;
  --ion-color-warning-tint: #fec536;

  /** danger **/
  --ion-color-danger: #f04636;
  --ion-color-danger-rgb: 240, 70, 54;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #be372b;
  --ion-color-danger-tint: #f94938;
}
