@import "../theme/variables-hexis";

#inbox-list {
  ion-list-header {
    color: $primary;
    font-family: $font-roboto-black;
    font-size: 30px;
    margin-bottom: 30px;
    letter-spacing: 3px;
  }

  ion-item {
    height: 60px;
    // color: $gold;
    transition: color 0.5s ease;

    .img-ion-icon,
    ion-icon {
      width: 30px;
      height: 30px;
      transition: all 1s;
    //   margin-inline-end: 20px;
    }

    ion-label {
      color: $dark-light;
      font-family: $font-roboto-black;
      font-size: 18px;
      text-transform: uppercase;
      transition: all 1s;
      border-bottom: 1px solid $dark-light;
    }

    &:hover,
    &.selected {
      ion-label,
      ion-icon {
        color: $primary;
      }
    }
  }
}

// ion-list {
//   padding-top: 0 !important;
//   padding-bottom: 0 !important;
//   --background: linear-gradient(135deg, transparent 35px, #ed3f32 0),
//     linear-gradient(-135deg, transparent 35px, #ed3f32 0);
//     background-size: 50% 50%;
//     background-repeat: no-repeat;
// }

// .list-ios {
//   background: linear-gradient(135deg, transparent 35px, #ed3f32 0),
//     linear-gradient(-135deg, transparent 35px, #ed3f32 0);
//     background-size: 50% 50%;
//     background-repeat: no-repeat;
// }

// ion-menu {
//   height: calc(100% - #{$header-height});
//   margin-top: $header-height;

//   ion-content {
//     --background: linear-gradient(135deg, transparent 35px, #ed3f32 0),
//       linear-gradient(-135deg, transparent 35px, #ed3f32 0);
//       --background-size: 50% 50%;
//       --background-repeat: no-repeat;

//     ion-list-header {
//       font-family: $font-roboto-black;
//       font-weight: $font-weight-bold;
//       font-size: calc(#{$font-size-menu} + 8px);
//       letter-spacing: 4px;
//       text-transform: uppercase;
//       --background: linear-gradient(135deg, transparent 35px, #ed3f32 0);
//       color: #fff;
//       margin-bottom: 15px;
//     }

//     ion-item {
//       height: 70px;
//       --background: #ed3f32;
//       color: #fff;
//       padding: 10px 0;
//       transition: color 0.5s ease;

//       ion-label {
//         font-family: $font-roboto-black !important;
//         font-size: $font-size-menu !important;
//         letter-spacing: 4px;
//         text-transform: uppercase;
//         transition: all 1s;
//       }

//       .img-ion-icon,
//       ion-icon {
//         width: 25px;
//         height: 25px;
//         --color: #fff !important;
//         transition: all 1s;
//         margin-inline-end: 20px;
//       }

//       &:hover,
//       &.selected {
//         ion-label,
//         ion-icon {
//           color: $light !important;
//         }
//       }
//     }
//   }
// }

// ion-backdrop {
//   opacity: 1;
//   backdrop-filter: blur(200px);
// }

// /* iphone X , XS, 11 Pro */
// @media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
//   ion-menu {
//     height: calc(100% - 90px);
//     margin-top: 90px;
//   }
// }

// /* iphone XR, 11 */
// @media only screen and (min-device-width: 414px) and (max-device-height: 896px) and (-webkit-device-pixel-ratio: 2) {
//   ion-menu {
//     height: calc(100% - 90px);
//     margin-top: 90px;
//   }
// }
