@import "../theme/variables-hexis";

ion-col a {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 0;

  ion-thumbnail {
    width: 93px;
    height: 93px;
  }

  p {
    margin: 12px 0;
    font-family: $font-roboto-medium;
    font-weight: $font-weight-medium;
    text-align: center;
    font-size: 16px;
    line-height: 18px;

    &.has-subName {
      font-family: $font-roboto-semibold;
      font-weight: $font-weight-semibold;
      text-transform: uppercase;
      margin-bottom: 0;

      + p {
        margin-top: 0;
        font-family: $font-roboto-regular;
        font-weight: $font-weight-regular;
      }
    }
  }
}
