@import "../../theme/variables-hexis";

#gallery {
  h1,
  p {
    padding-left: 15px;
    padding-right: 15px;
  }

  .swiper {
    position: relative;
    margin-bottom: 25px;

    .swiper-container {
      overflow: visible;
    }

    .slide-prev,
    .slide-next {
      position: absolute;
      height: 100%;
      top: 0;
      z-index: 2;
      padding: 0 10px;
      display: flex;
      align-items: center;

      ion-icon {
        color: $dark;
        height: 24px;
        width: 24px;
      }
    }

    .slide-prev {
      left: 0;
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0.5),
        transparent
      );
    }

    .slide-next {
      right: 0;
      background: linear-gradient(
        to left,
        rgba(255, 255, 255, 0.5),
        transparent
      );
    }
    .swiper-pagination {
      position: absolute;
      z-index: 1;
      bottom: -20px;

      .swiper-pagination-bullet {
        opacity: 0.6;
      }
    }

    .picture-infos {
      display: flex;
      align-items: center;
      padding: 5px 15px 10px 15px;
      width: 100%;

      .picture-txt-wrapper {
        width: 70%;
        text-align: left;

        .picture-title {
          font-size: 17px;
          line-height: 20px;
          color: $dark;
          font-family: $font-roboto-bold;
          font-weight: $font-weight-bold;
          margin-top: 10px;
          margin-bottom: 6px;
          padding: 0;
        }

        .picture-author {
          font-size: 12px;
          line-height: 14px;
          color: $dark-light;
          font-family: $font-roboto-regular;
          font-weight: $font-weight-regular;
          margin: 0;
          padding: 0;
        }
      }

      .picture-date {
        width: 30%;
        text-align: right;
        font-size: 12px;
        line-height: 14px;
        color: $dark-light;
        font-family: $font-roboto-regular;
        font-weight: $font-weight-regular;
      }
    }
  }

  ion-slide {
    display: flex;
    flex-direction: column;

    ion-img {
      height: 250px;
    }
  }

  .album-container {
    padding: 15px 10px;

    h3 {
      padding: 0 5px;
      margin-bottom: 50px;
      font-size: 18px;
      line-height: 22px;
      font-family: $font-roboto-semibold;
      font-weight: $font-weight-semibold;
      text-transform: none;
    }

    ion-col:nth-child(even) {
      margin-top: -50px;
    }

    ion-thumbnail {
      width: 100%;
      height: calc(50vw - 20px);
    }
  }

  #popup-slideshow {
    display: flex;
    position: sticky;
    width: 100vw;
    height: calc(100vh - #{$header-height});
    bottom: -50px;
    align-items: center;
    z-index: 2;

    .blur {
      background: rgba(255, 255, 255, 0.43);
      backdrop-filter: blur(5px);
      height: 100%;
      width: 100%;
      position: absolute;

      .popup-close-icon {
        position: absolute;
        top: 20px;
        right: 20px;
        width: 2em;
        height: 2em;
      }
    }

    .swiper {
      padding: 0 8px;
      width: 100%;

      .slide-prev,
      .slide-next {
        height: 100%;
      }

      .swiper-pagination {
        top: auto;
        bottom: -30px;
      }
    }

    .slide-prev {
      left: 8px;
    }

    .slide-next {
      right: 8px;
    }

    &.video {
      .blur {
        background: rgba(0, 0, 0, 0.43);
      }

      .slide-prev {
        background: linear-gradient(to right, rgba(0, 0, 0, 0.5), transparent);

        ion-icon {
          color: #fff;
        }
      }

      .slide-next {
        background: linear-gradient(to left, rgba(0, 0, 0, 0.5), transparent);

        ion-icon {
          color: #fff;
        }
      }
    }
  }
}
