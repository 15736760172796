@import "../../theme/variables-hexis";

#idea-page ion-content {
  --padding-start: 15px;
  --padding-end: 15px;

  ion-label {
    font-family: $font-roboto-semibold;
  }

  .hexis-btn {
    --background: linear-gradient(-135deg, transparent 12px, #4A4A4A 0);
    --background-activated: linear-gradient(-135deg, transparent 12px, #4A4A4A 0);
    background-repeat: no-repeat;
    width: 250px;
    margin: 0 auto 10px auto;
  }

  .btn-send {
    --background: linear-gradient(-135deg, transparent 12px, #ED3F32 0);
    --background-activated: linear-gradient(-135deg, transparent 12px, #ED3F32 0);
    background-repeat: no-repeat;
    width: 250px;
    margin: 0 auto 5em auto;
  }

  .remove_white_space {
    white-space:normal;
  }

  ion-img, div.progress, .hexis-btn {
    margin-top: 3vh;
  }
}
