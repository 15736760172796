@import "../theme/variables-hexis";

ion-header#main-header {
  .main-header {
    --background: #f5f7fa;
  }

  .logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90px;
  }
}