@import "../theme/variables-hexis";

.img-banner {
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 240px;
  background-repeat: no-repeat;
  background-color: #000;
}

#page {
  padding: 0 15px;

  > div + ion-button {
    margin-top: 30px;
  }

  img {
    &[data-align="center"] {
      margin: auto;
      display: block;
    }

    &[data-align="right"] {
      margin-left: auto;
      display: block;
    }
  }

  .content-img {
    display: flex;
    column-gap: 10px;

     > div {
       flex: auto;

       p {
         font-size: 11px;
         line-height: 13px;
         font-family: $font-roboto-regular;
         font-weight: $font-weight-regular;
         color: $dark-extra-light;
         margin: 5px 0 0 0;
       }
     }
  }
}
