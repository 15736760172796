@import "../theme/variables-hexis";
#agenda {
  --background: #ECEDEF;
}

#note-list {
  padding-top: 63px;

  ion-toolbar {
    --background: #fff;
    position: fixed;
    margin-top: -63px;
    padding: 0 20px;

    ion-title {
      font-family: $font-roboto-medium;
      font-weight: $font-weight-medium !important;
      font-size: 22px !important;
      line-height: 27px;
      text-transform: uppercase;
    }
  }

  .note-row {
    display: flex;
    margin: 15px;
    background: #fff;

    .note-img {
      width: 50px;

      ion-thumbnail {
        --size: 100%;
        height: 54px;
      }
    }

    .note-txt {
      width: calc(100% - 50px);
      padding: 10px 20px;

      .title-date {
        display: flex;
        justify-content: space-between;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        h2 {
          font-family: $font-roboto-bold;
          font-weight: $font-weight-bold;
          font-size: 11px;
          line-height: 14px;
          color: $dark-light;
        }

        span {
          font-family: $font-roboto-medium;
          font-weight: $font-weight-medium;
          font-size: 9px;
          line-height: 14px;
          color: $primary;
        }
      }

      p {
        font-family: $font-roboto-medium;
        font-weight: $font-weight-medium;
        font-size: 11px;
        line-height: 14px;
        margin-block-start: 6px;
        margin-block-end: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }


    &.active {
      background: $primary;

      .title-date h2, .title-date span, p {
        color: #fff;
      }
    }
  }
}
