@import "../../theme/variables-hexis.scss";

#statement {
  ion-toolbar {
    background: #f5f7fa;
    --border-color: transparent;
  }

  ion-content {
    ion-item {
      --background: #fff !important;
      margin: 0.5em 0 1em 0;
      border-radius: 4px;
    }
    ion-button {
      width: 250px;
      margin: 0 auto 6em auto;
    }
    ion-button.btn-add {
      margin: 2em auto 2em auto;
      --background: linear-gradient(-135deg, transparent 12px, #4a4a4a 0);
      --background-activated: linear-gradient(
        -135deg,
        transparent 12px,
        #4a4a4a 0
      );
      background-repeat: no-repeat;
      width: 250px;
    }
  }
}
