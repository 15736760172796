@import "../../theme/variables-hexis";

#add-photo {
  text-align: center;
  margin-top: 50px;

  form {
    width: 85%;
    margin: auto;
    margin-top: 30px;

    ion-button.btn-add {
      margin: 2em auto 2em auto;
      --background: linear-gradient(-135deg, transparent 12px, #4A4A4A 0);
      --background-activated: linear-gradient(-135deg, transparent 12px, #4A4A4A 0);
      background-repeat: no-repeat;
      width: 250px;
    }

    .checkbox-center {
      margin-top: 0;
      margin-bottom: 20px;
    }
  }
}
