@import "variables-hexis";

/*_______________________ GLOBAL _______________________ */
.hidden {
  display: none !important;
}

.opacity0 {
  opacity: 0 !important;
}

ion-menu {
  z-index: 50;
}

ion-title {
  font-family: $font-roboto-black;
  font-size: 20px;
  letter-spacing: 0px;
  text-overflow: unset;
  white-space: unset;
}

ion-card.ios {
  // border-radius: 4px;
  box-shadow: none;
}

ion-card.md {
  box-shadow: none;
  // box-shadow: rgb(0 0 0 / 12%) 0px 4px 16px;
}

ion-tab-bar {
  --background: #fff;
}

/* Screen */
@media screen and (min-width: 1400px) {
  body {
    background-color: rgb(245, 247, 250);
  }
  .ion-page {
    width: 800px;
    margin: auto;
  }
  #gallery #popup-slideshow {
    max-width:100%;
  }
}

/* iphone X , XS, 11 Pro */
@media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  body {
    padding-top: constant(safe-area-inset-top);
    padding-top: env(safe-area-inset-top);
  }
  .toolbar-footer {
    margin-bottom: constant(safe-area-inset-bottom);
    margin-bottom: env(safe-area-inset-bottom);
  }
}

/* iphone XR, 11 */
@media only screen and (min-device-width: 414px) and (max-device-height: 896px) and (-webkit-device-pixel-ratio: 2) {
  body {
    padding-top: constant(safe-area-inset-top);
    padding-top: env(safe-area-inset-top);
  }
  .toolbar-footer {
    margin-bottom: constant(safe-area-inset-bottom);
    margin-bottom: env(safe-area-inset-bottom);
  }
}

/*_______________________ MENU _______________________ */
.menu-content-open {
  pointer-events: all;
}

/*_______________________ CONTENT _______________________ */

ion-header {
  --ion-background-color: #f5f7fa;
}

ion-content {
  --padding-bottom: 50px;
  --ion-background-color: #f5f7fa;
}

ion-card {
  --ion-background-color: #fff;
}

h2,
h3 {
  margin: 0;
}

h1 {
  font-family: $font-roboto-bold;
  font-weight: $font-weight-bold;
  font-size: $font-size-h1;
  line-height: $line-height-h1;
  color: $color-h1;
  margin: 20px 0;
}

h2 {
  font-family: $font-roboto-regular;
  font-weight: $font-weight-regular;
  font-size: $font-size-h2;
  line-height: $line-height-h2;
  color: $color-h2;
}

h3 {
  font-family: $font-roboto-semibold;
  font-weight: $font-weight-semibold;
  font-size: $font-size-h3;
  line-height: $line-height-h3;
  color: $color-h3;
}

p,
.paragraph {
  font-family: $font-roboto-regular;
  font-weight: $font-weight-regular;
  font-size: $font-size-p;
  line-height: $line-height-p;
  color: $color-p;

  strong,
  b {
    font-family: $font-roboto-bold;
    font-weight: $font-weight-semibold;
  }
}

a {
  color: $primary;
  text-decoration: none;
}

/*_______________________ COMPONENT _______________________ */

/*_____________ button _____________ */

ion-button.hexis-btn {
  --background: linear-gradient(-135deg, transparent 12px, #ed3f32 0);
  --background-activated: linear-gradient(-135deg, transparent 12px, #ce1f12 0);
  background-repeat: no-repeat;
  width: 250px;
  margin: 0 auto 10px auto;
}

ion-button.btn-margin {
  margin: 2em auto 6em auto;
}

.btn-send,
ion-button {
  height: 43px;
  text-transform: uppercase;
  font-family: $font-roboto-bold;
  font-weight: $font-weight-bold;
  font-size: 12px;
  line-height: 16px;
  --border-radius: 50px;
  --box-shadow: none;
  letter-spacing: 0;

  &.btn-width-md {
    width: 250px;
    margin: 10px auto;
  }

  &:disabled {
    opacity: 0.5;

    &:hover {
      cursor: not-allowed;
    }
  }
}

/*_____________ badge _____________ */
ion-badge {
  font-family: $font-roboto-bold;
  font-weight: $font-weight-bold;
  font-size: 11px;
  color: $dark;
  background: $light;
}
ion-tab-button ion-badge {
  top: 0;
}

/*_____________ progess _____________ */
.progress p {
  font-family: $font-roboto-bold;
  font-weight: $font-weight-bold;
  font-size: 13px;
  margin-top: 5px;
  color: $primary;
  text-transform: uppercase;
}

.sub-menu {
  ion-text {
    padding-left: 1em;
    color: $primary;
    font-family: $font-roboto-black;
    font-size: 25px;
    letter-spacing: 3px;
  }

  .padd-t {
    margin-top: 2em;
  }

  ion-list {
    margin-bottom: 5em;
  }

  ion-item {
    ion-icon {
      width: 30px;
      height: 30px;
      transition: all 1s;
      color: $primary;
    }

    ion-label {
      color: $dark-light;
      font-family: $font-roboto-black;
      font-size: 14px;
      text-transform: uppercase;
      transition: all 1s;
    }
  }
}
