@import "../../theme/variables-hexis.scss";

#account {
  ion-toolbar {
    background: #f5f7fa;
    --border-color: transparent;
  }

  ion-content {
    --background: #f5f7fa;
    // --padding-top: 40px;
    --padding-bottom: 80px;
    text-align: center;

    ion-img {
      height: 6em;
      &.logo {
        margin-top: 5em !important;
      }
    }

    .delete-account {
      margin-top:20px;
      margin-bottom:40px;
    }

    h1 {
      font-family: $font-roboto-semibold;
      font-weight: $font-weight-semibold;
      font-size: 20px;
      line-height: 24px;
    }

    p {
      font-family: $font-roboto-medium;
      font-weight: $font-weight-medium;
      font-size: 14px;
      line-height: 18px;
      margin-top: 30px;
    }

    a {
      font-family: $font-roboto-medium;
      font-weight: $font-weight-medium;
      font-size: 13px;
      line-height: 16px;
      color: $dark-light;
      &:hover {
        color: $primary;
      }
    }

    &.bg-gradient {
      --background: linear-gradient(
          rgba(64, 64, 64, 0.2) 80%,
          rgb(201, 1, 25) 100%
        ),
        center center / cover no-repeat fixed;
      --background: #f5f7fa;

      .logo {
        margin: 5vh auto 40vh auto;
      }

      ion-button {
        width: 250px;
        margin: 0 auto 10px auto;
      }
    }
  }
}
