@import "../theme/variables-hexis";

#list {
  a {
    text-decoration: none;
  }

  ion-thumbnail {
    --size: 100%;
    width: 90px;
    height: 90px;
    background-color: #d4d4d4;
    border-radius: 5px;
    text-align: center;
  }

  ion-card {
    display: flex;
    border-left: 5px solid $dark-light;
    min-height: 120px;
    align-items: center;
  }

  ion-card-header {
    padding-inline: unset;
  }

  ion-card-title {
    font-size: 16px;
  }

  .important {
    border-left: 5px solid $primary;
  }

  .read {
    opacity: 0.5;
  }
}
