@import "../../theme/variables-hexis";

#error, #success {
  .message-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    text-align: center;
    width: 80%;
    margin: auto 10%;

    .error-icon {
      width: 44px;
      height: 44px;
      margin-right: 13px;
    }

    h1 {
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: $font-roboto-semibold;
      font-weight: $font-weight-semibold;
      font-size: 20px;
      line-height: 24px;
      text-transform: uppercase;

      span {
        max-width: calc(100% - 57px);
        text-align: left;
      }
    }

    p {
      font-family: $font-roboto-medium;
      font-weight: $font-weight-medium;
      font-size: 14px;
      line-height: 18px;
    }

    a {
      font-family: $font-roboto-medium;
      font-weight: $font-weight-medium;
      font-size: 13px;
      line-height: 16px;
      text-decoration: underline;
    }
  }
}


#error ion-content {
  --background: #000;

  .message-wrapper {
    height: 100%;
  }

  h1 {
    color: $light;
  }

  p {
    color: $light;
  }

  a {
    color: rgba(255, 255, 255, .5);

    &:hover {
      color: $primary;
    }
  }
}

#success {

  .element-wrapper {
    width: 80%;
    margin: 30px auto;

    h2 {
      margin-top: 10px;
      margin-left: 10px;
      font-family: $font-roboto-bold;
      font-weight: $font-weight-bold;
      font-size: 17px;
      line-height: 20px;
    }
  }

  h1 {
    color: $dark;
  }

  p {
    color: $dark;
  }

  a {
    color: $primary;

    &:hover {
      color: $dark-light;
    }
  }
}
