@import "../../theme/variables-hexis";
#node-tools {
  display: flex;
  justify-content: center;
  align-items: center;

  ion-button {
    --background: transparent;
    --background-activated: none;
    padding: 0;

    ion-icon {
      margin-right: 0;
    }
  }

  .note-date {
    font-family: $font-roboto-bold;
    font-weight: $font-weight-bold;
    font-size: 18px;
    line-height: 21px;
    color: $primary;
    padding: 0 15px;
  }
}
